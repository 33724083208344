import KanbanService from '@/services/kanbanService'

/* eslint-disable no-param-reassign */
export default {
  data() {
    return {
      report: [],
    }
  },
  methods: {
    async canSendEmail(_user, _loggedManager) {
      const ret = this.isMyUser(_user, _loggedManager)

      if (_loggedManager.permission_type === 'root') {
        return true
      }
      return ret
    },

    async canSendPaymentLink(_user, _loggedManager) {
      const ret = this.isMyUser(_user, _loggedManager)

      if (_loggedManager.permission_type === 'root') {
        return true
      }
      return ret
    },

    getRuleReport() {
      return this.report
    },

    async getCard(userId) {
      if (!userId) {
        return null
      }
      const kanbanService = new KanbanService()
      return kanbanService.getKanbanCard(userId).then(response => response.data.data.card)
    },

    async isMyUser(_user, _loggedManager) {
      if (!_user || !_loggedManager) {
        return false
      }

      this.report = []
      const now = new Date()
      const nowSub30 = new Date()
      const nowSub15 = new Date()
      nowSub15.setDate(now.getDate() - 15)
      nowSub30.setDate(now.getDate() - 30)
      const endOfPlan = _user.last_paid_quota ? new Date(_user.last_paid_quota.end_of_plan) : new Date()
      const sellerOfLastPlan = (_user.last_paid_quota && _user.last_paid_quota.account_manager_id) ? _user.last_paid_quota.account_manager_id : null
      // const courtesy = !_user.last_paid_quota || _user.quotas.plan_id === 5

      let accountManagerUntil = null
      if (_user.settings && _user.settings.account_manager_at !== undefined) {
        accountManagerUntil = new Date(_user.settings.account_manager_at)
        accountManagerUntil.setDate(accountManagerUntil.getDate() + 30)
      }

      // let cercManagerUntil = null
      let cercManager = null
      if (_user.settings && _user.settings.cerc_right_until !== undefined && (new Date(_user.settings.cerc_right_until)) > now) {
        // cercManagerUntil = new Date(_user.settings.cerc_right_until)
        cercManager = _user.settings.cerc_right_manager_id
      } else if (_user.settings && _user.settings.cerc_preference_until !== undefined && (new Date(_user.settings.cerc_preference_until)) > now) {
        // cercManagerUntil = new Date(_user.settings.cerc_preference_until)
        cercManager = _user.settings.cerc_preference_manager_id
      }

      const activeSignature = !!_user.last_paid_quota
          && (_user.last_paid_quota.type_of_acquisition === 'buy' || _user.last_paid_quota.type_of_acquisition === 'automatic')
          && endOfPlan >= now

      const myUser = (sellerOfLastPlan === _loggedManager.id && activeSignature)
        || (cercManager !== null && cercManager === _loggedManager.id)

      this.report.push('Validadores:')
      this.report.push(`  É meu: ${myUser ? '<strong class="text-success">sim</strong>' : 'não'}`)
      if (myUser) {
        return true
      }

      const userHasAnotherEvOrCerc = _user.settings && (
        (
          _user.settings.account_manager_id
            && _loggedManager
            && _loggedManager.id !== _user.settings.account_manager_id
            && accountManagerUntil
            && accountManagerUntil > now
        ) || cercManager !== null
      )

      this.report.push(`  Usuário está atribuído a outro EV ou CERC? ${userHasAnotherEvOrCerc ? '<strong class="text-danger">sim</strong>' : 'não'}`)
      if (userHasAnotherEvOrCerc) {
        return false
      }

      const kanbanCard = await this.getCard(_user.id)
      this.report.push(`  Usuário está no meu kanban ? ${!!kanbanCard && _loggedManager && kanbanCard.account_manager_id === _loggedManager.id ? '<strong class="text-success">sim</strong>' : '<strong class="text-danger">não</strong>'}`)
      return !!kanbanCard && _loggedManager && kanbanCard.account_manager_id === _loggedManager.id
    },
  },
}
